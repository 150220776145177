const scale = [
  12, // 00 = 0.75 rem
  14, // 01 = 0.875 rem
  16, // 02 = 1 rem
  18, // 03 = 1.125 rem
  20, // 04 = 1.25 rem
  24, // 05 = 1.5 rem
  28, // 06 = 1.75 rem
  32, // 07 = 2 rem
  36, // 08 = 2.25 rem
  42, // 09 = 2.625 rem
  48, // 10 = 3 rem
  54, // 11 = 3.375 rem
  60, // 12 = 3.75 rem
  68, // 13 = 4.25 rem
  76, // 14 = 4.75 rem
  84, // 15 = 5.25 rem
  92, // 16 = 5.75 rem
];

export default scale;
